import React, { FC } from "react";
import { type } from "./ContentTypeInput";

interface Props {
  content: string | FileList | null;
  setContent: (content: string | FileList | null) => void;
  contentType: type;
}

const ContentInput: FC<Props> = ({ content, setContent, contentType }) => {
  return (
    <div>
      <label
        htmlFor="content"
        className="block text-sm font-medium text-gray-700 dark:text-gray-200"
      >
        Content
      </label>
      <div className="mt-1"></div>
      {contentType.value === "text" && (
        <textarea
          rows={6}
          name="content"
          id="content"
          className="sappearance-none block w-full whitespace-pre-wrap rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400  shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 dark:border-gray-900 dark:bg-gray-600 dark:text-gray-200 dark:focus:border-purple-500 dark:focus:ring-purple-500 sm:text-sm"
          defaultValue={content as string}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Very important message"
        />
      )}
      {contentType.value === "file" && (
        <input
          name="content"
          id="content"
          type="file"
          onChange={(e) => setContent(e.target.files)}
          accept=".pdf"
          className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-purple-600 focus:bg-white focus:text-gray-700 focus:outline-none dark:border-gray-900 dark:bg-gray-600 dark:text-gray-200 dark:focus:border-purple-600 dark:focus:bg-gray-800 dark:focus:text-gray-200 dark:focus:outline-none"
        />
      )}

      {contentType.value === "image" && (
        <input
          name="content"
          id="content"
          type="file"
          onChange={(e) => setContent(e.target.files)}
          accept=".png, .jpg, .jpeg"
          className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-purple-600 focus:bg-white focus:text-gray-700 focus:outline-none dark:border-gray-900 dark:bg-gray-600 dark:text-gray-200 dark:focus:border-purple-600 dark:focus:bg-gray-800 dark:focus:text-gray-200 dark:focus:outline-none"
        />
      )}
      {contentType.value === "video" && (
        <input
          id="title"
          name="title"
          type="text"
          value={content as string}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Link to Video"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 dark:border-gray-900 dark:bg-gray-600 dark:text-gray-200 dark:focus:border-purple-500 dark:focus:ring-purple-500 sm:text-sm"
        />
      )}
      {contentType.value === "doc" && (
        <input
          id="title"
          name="title"
          type="text"
          value={content as string}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Link to Public/Shared Google Doc"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 dark:border-gray-900 dark:bg-gray-600 dark:text-gray-200 dark:focus:border-purple-500 dark:focus:ring-purple-500 sm:text-sm"
        />
      )}
    </div>
  );
};

export default ContentInput;
