import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { db, logClient } from "../../firebase";

export const userDocRelationColl = collection(db, "UserDocRelation");

type userDocRelationType = {
  docId: string | undefined;
  userId: string;
  hasOpen: boolean;
  hasRead: boolean;
  reflection: string;
  email: string;
};

export const AddUserDocRelation = async (
  userDocRelation: userDocRelationType
) => {
  try {
    const userDocRelationRef = await addDoc(userDocRelationColl, {
      ...userDocRelation,
      lastUpdated: serverTimestamp(),
    });

    logClient.log("Document written with ID: ", userDocRelationRef.id);
  } catch (error) {
    logClient.warn(error);
  }
};

export const UpdateUserDocRelation = async (
  userDocRelationId: string,
  data: any
) => {
  try {
    const userDocRelationRef = doc(db, "UserDocRelation", userDocRelationId);

    await setDoc(
      userDocRelationRef,
      {
        ...data,
        lastUpdated: serverTimestamp(),
      },
      { merge: true }
    );

    logClient.log(`Document with id: ${userDocRelationId} updated`);
  } catch (error) {
    logClient.warn(error);
  }
};

export const GetUserDocRelation = async (UserDocId: string) => {
  const userDocRef = doc(db, "UserDocRelation", UserDocId);
  try {
    const docProfSnap = await getDoc(userDocRef);
    if (docProfSnap.exists()) {
      return docProfSnap.data();
    } else {
      logClient.warn("userDoc Relation not found");
    }
  } catch (error) {
    logClient.warn(error);
  }
};

export const DeleteUserDocRelation = async (UserDocId: string) => {
  const userDocRef = doc(db, "UserDocRelation", UserDocId);
  try {
    await deleteDoc(userDocRef);
    logClient.log(`Doc with id: ${userDocRef.id} deleted`);
  } catch (error) {
    logClient.warn(error);
  }
};

export const GetAllUserNames = async () => {
  try {
    const users = await getDocs(collection(db, "users"));
    return users.docs.map((user) => user.data());
  } catch (error) {
    logClient.warn(error);
    return [];
  }
};

export const GetUserDocByUserIdDocId = async (
  userId: string | undefined,
  docId: string
) => {
  try {
    if (userId === undefined) throw new Error("User not found");
    const q = query(
      userDocRelationColl,
      where("userId", "==", userId),
      where("docId", "==", docId)
    );

    const querySnapshot = await getDocs(q);

    let UserDocData = {};

    querySnapshot.forEach(
      (doc) => (UserDocData = { ...doc.data(), id: doc.id })
    );

    return UserDocData;
  } catch (error) {
    logClient.warn(error);
  }
};

export const GetDocRates = async (docId: string) => {
  const q = query(userDocRelationColl, where("docId", "==", docId));
  try {
    const querySnapshot = await getDocs(q);
    let hasOpen = 0;
    let hasRead = 0;
    querySnapshot.forEach((userDoc) => {
      const read = userDoc.get("hasRead");
      const opened = userDoc.get("hasOpen");
      if (read) hasRead++;
      if (opened) hasOpen++;
    });

    return { hasRead, hasOpen };
  } catch (error) {}
};
