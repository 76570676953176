import React, { FC } from "react";

interface Props {
  title: string;
  setTitle: (title: string) => void;
}

const TitleInput: FC<Props> = ({ title, setTitle }) => {
  return (
    <div>
      <label
        htmlFor="title"
        className="block text-sm font-medium text-gray-700 dark:text-gray-200"
      >
        Title
      </label>
      <div className="mt-1">
        <input
          id="title"
          name="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="eg. New Product Direction"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 dark:border-gray-900 dark:bg-gray-600 dark:text-gray-200 dark:focus:border-purple-500 dark:focus:ring-purple-500 sm:text-sm"
        />
      </div>
    </div>
  );
};

export default TitleInput;
