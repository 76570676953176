import React, { FC } from "react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/outline";

interface Props {
  dueDate: any;
  setDueDate: (dueDate: any) => void;
}

const DueDateInput: FC<Props> = ({ dueDate, setDueDate }) => {
  return (
    <div>
      <label
        htmlFor="duedate"
        className="block text-sm font-medium text-gray-700 dark:text-gray-200"
      >
        Due Date
      </label>
      <div className="relative">
        <DatePicker
          name="duedate"
          id="duedate"
          selected={dueDate}
          onChange={(date) => setDueDate(date)}
          minDate={new Date()}
          className="w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 dark:border-gray-900 dark:bg-gray-600 dark:text-gray-200 dark:focus:border-purple-500 dark:focus:ring-purple-500 sm:text-sm"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
};
export default DueDateInput;
