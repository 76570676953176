import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import SignUpForm from "../components/signIn/SignupForm";
import { AuthContext } from "../context/AuthContext";
import { LoaderContext } from "../context/LoaderContext";
import { GetRedirectResult } from "../firebase/auth/AuthHelper";

export default function SignUp() {
  const { user } = useContext(AuthContext);
  const loaderCtx = useContext(LoaderContext);
  const history = useHistory();

  useEffect(() => {
    loaderCtx?.showLoader();
    GetRedirectResult().finally(() => {
      loaderCtx?.hideLoader();
      if (user) history.push("dashboard");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <>
      <div className="container mx-auto w-5/6">
        <SignUpForm />
      </div>
    </>
  );
}
