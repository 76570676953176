import { DocumentData } from "@firebase/firestore";
import React, { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AuthorView from "../components/document/AuthorView";
import ReaderView from "../components/document/ReaderView";
import { AuthContext } from "../context/AuthContext";
import { GetDocData } from "../firebase/controller/docViewController";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";
import AuthorUserView from "../components/document/AuthorUserView";
import Content from "../components/document/Content";
import { LoaderContext } from "../context/LoaderContext";

interface Params {
  docId: string;
  userId: string;
}

const Document: FC = () => {
  const { docId, userId } = useParams<Params>();
  const { user } = useContext(AuthContext);
  const loaderCtx = useContext(LoaderContext);
  const [data, setData] = useState<DocumentData | undefined>({});

  const isAuthor = data?.doc?.author === user?.uid;

  const history = useHistory();

  useEffect(() => {
    loaderCtx?.showLoader();

    if (docId === "undefined") {
      history.replace("/dashboard");
    }
    GetDocData(docId)
      .then((data) => setData(data))
      .finally(() => loaderCtx?.hideLoader());

    return () => setData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  const getReaderEmail = (userId: string) => {
    return data?.userDoc?.find((u: any) => u.userId === userId).email;
  };

  const handleBackArrowClick = () => {
    if (userId) history.goBack();
    history.replace("/dashboard");
  };
  return (
    <>
      <div className="m-4 mt-14 h-full w-full p-10 md:p-0">
        <div className="justify-left mb-5 flex items-center space-x-1 align-middle">
          <ArrowLeftIcon
            className="h-6 w-6 stroke-current hover:text-purple-500 dark:text-gray-200 dark:hover:text-purple-500"
            onClick={handleBackArrowClick}
          />
          <div className="text-3xl font-medium dark:text-gray-200">
            <span> {data?.doc?.title}</span>
            {userId && (
              <span className="text-lg font-medium">
                &nbsp;response by {getReaderEmail(userId)}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-8">
          <div className="h-[80vh] max-h-[80vh] overflow-scroll whitespace-pre-wrap rounded border border-gray-500 p-2 dark:border-purple-400 dark:text-gray-200 md:h-auto">
            <Content doc={data?.doc} />
          </div>
          {!isAuthor && <ReaderView data={data} />}
          {!userId && isAuthor && <AuthorView data={data} />}
          {userId && isAuthor && (
            <AuthorUserView
              data={
                //Just get the data we need
                {
                  doc: data?.doc,
                  user: [...data?.userDoc].find(
                    (item) => item.userId === userId
                  ),
                }
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Document;
