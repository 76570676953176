import {
  collection,
  DocumentData,
  onSnapshot,
  query,
  where,
} from "@firebase/firestore";
import React, {
  FC,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import { UpdateUserDocRelation } from "../../firebase/controller/userDocController";
import { db, logClient } from "../../firebase";
import {
  DateFormater,
  formatChallengeQuestion,
} from "../../utils/formatHelper";
import Button from "../lib/Button";
import { NotificationContext } from "../../context/NotificationContext";

interface Props {
  data: DocumentData | undefined;
}
const ReaderView: FC<Props> = ({ data }) => {
  const [answer, setAnswer] = useState("");
  const [userDoc, setUserDoc] = useState<any>({});
  const [submitted, setSubmitted] = useState(false);

  const challengeQuestion = data?.doc?.challengeQuestion ?? "";
  const { docId } = useParams<{ docId: string }>();
  const { user } = useContext(AuthContext);
  const notificationCtx = useContext(NotificationContext);

  //RealTime listener for sumbit + some logic for hasOpen
  useEffect(() => {
    const q = query(
      collection(db, "UserDocRelation"),
      where("userId", "==", user?.uid),
      where("docId", "==", docId)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setUserDoc({ ...doc.data(), id: doc.id });

        if (!doc.data()?.hasOpen) {
          UpdateUserDocRelation(doc.id, { hasOpen: true });
        }
        if (doc.data().hasRead) setSubmitted(true);
      });
    });
    return () => unsubscribe();
  }, [user?.uid, docId, submitted]);

  const handleSubmit = async (e: SyntheticEvent) => {
    try {
      if (challengeQuestion && !answer) {
        notificationCtx?.DisplayMessage("error", "Answer cannot be blank");
        return;
      }
      e.preventDefault();
      await UpdateUserDocRelation(userDoc.id, {
        hasRead: true,
        reflection: answer,
      });

      setSubmitted(true);
      notificationCtx?.DisplayMessage("success", "Answer Submitted");
    } catch (error) {
      logClient.warn(error);
    }
  };

  if (!data) return <> </>;

  return (
    <>
      <div className="grid auto-rows-min space-y-8 divide-y-2 divide-solid divide-gray-500 divide-opacity-40 dark:divide-purple-400 dark:text-gray-200">
        <div className="flex h-fit flex-col">
          <div className="!mt-6 grid grid-cols-2">
            <span className="text-md font-bold">Sent</span>
            <span className="text-md text-center">
              {DateFormater(data?.doc?.createdAt)}
            </span>
          </div>
          <div className="grid grid-cols-2">
            <span className="text-md font-bold">Due</span>
            <span className="text-md text-center">
              {DateFormater(data?.doc?.dueDate)}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-md !mt-6 font-bold">Challenge Question</span>
          <span className="text-md">
            {formatChallengeQuestion(challengeQuestion) || "N/A"}
          </span>
          {challengeQuestion && !submitted && (
            <textarea
              rows={6}
              name="content"
              id="content"
              className="sappearance-none mt-3 block w-full whitespace-pre-wrap rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400  shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 dark:border-gray-900 dark:bg-gray-600 dark:text-gray-200 dark:focus:border-purple-500 dark:focus:ring-purple-500 sm:text-sm"
              defaultValue={answer}
              onChange={(e) => setAnswer(e.target.value)}
              placeholder="Very Important Answer"
            />
          )}

          {challengeQuestion && submitted && (
            <span className="mt-5 border-t-2 border-gray-500 pt-5 text-gray-700 text-opacity-80 dark:border-purple-400 dark:text-gray-200 dark:text-opacity-80">
              {userDoc.reflection}
            </span>
          )}
          {!submitted && (
            <Button
              variant="default"
              fullWidth
              className="mt-2"
              onClick={handleSubmit}
            >
              {challengeQuestion ? "Sumbit" : "Mark as Read"}
            </Button>
          )}
          {submitted && (
            <span className="text-center text-xl font-bold text-green-400">
              Completed
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default ReaderView;
