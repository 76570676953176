import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const { authenticated, loadingAuthState } = useContext(AuthContext);

  if (loadingAuthState) {
    return (
      <div>
        <span className="text-lg">Loading...</span> {/*spinner*/}
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        authenticated ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { prevPath: rest.path } }}
          />
        )
      }
    ></Route>
  );
};

export default PrivateRoute;
