import {
  collection,
  addDoc,
  getDoc,
  setDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { db, logClient } from "../../firebase";

const users = collection(db, "users");

type userType = {
  email: string;
  name: string;
  password: string;
  profilePic: string;
};

type anonUserType = {
  email: string;
  userVerified: boolean;
};

export const AddUser = async (user: userType | anonUserType) => {
  try {
    const userRef = await addDoc(users, {
      ...user,
      createdAt: serverTimestamp(),
    });
    logClient.log("Document written with ID: ", userRef.id);

    await setDoc(
      doc(db, "users", userRef.id),
      {
        id: userRef.id,
        email: user.email,
      },
      { merge: true }
    );
    return userRef.id;
  } catch (error) {
    logClient.warn(error);
  }
};
export const UpdateUser = async (userId: string, data: any) => {
  try {
    const userRef = doc(db, "users", userId);
    await setDoc(
      userRef,
      {
        timestamp: serverTimestamp(),
        ...data,
      },
      { merge: true }
    );
    logClient.log(`Document with id: ${userId} updated`);
  } catch (error) {
    logClient.warn(error);
  }
};
//get
export const GetUser = async (userId: string) => {
  const userRef = doc(db, "users", userId);
  try {
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return userSnap.data();
    }
    return;
  } catch (error) {
    logClient.warn(error);
  }
};

export const DeletUser = async (userId: string) => {
  const userRef = doc(db, "users", userId);
  try {
    await deleteDoc(userRef);
    logClient.log(`User with id: ${userRef.id} deleted`);
  } catch (error) {
    logClient.warn(error);
  }
};

export const GetEmailFromId = async (userId: string) => {
  if (!userId) {
    return;
  }
  const userRef = doc(db, "users", userId);
  try {
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const email = userSnap.get("email");

      return email;
    }
  } catch (error) {
    logClient.warn(error);
  }
};

export const GetUsernameFromId = async (userId: string) => {
  if (!userId) {
    return;
  }
  const userRef = doc(db, "users", userId);
  try {
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const username = userSnap.get("username");

      return username;
    }
  } catch (error) {
    logClient.warn(error);
  }
};
