import React, { useContext } from "react";
import { Routes } from "./routes/Routes";
import ErrorDisplay from "./components/NotifactionDisplay";
import LoaderDisplay from "./components/LoaderDisplay";
import { LoaderContext } from "./context/LoaderContext";
import classNames from "classnames";

function App() {
  const loaderCtx = useContext(LoaderContext);
  return (
    <>
      <div
        className={classNames(
          "flex h-full w-screen dark:bg-black md:h-screen",
          { "opacity-50": loaderCtx?.showLoad }
        )}
      >
        <Routes />
      </div>
      <ErrorDisplay />
      <LoaderDisplay showLoad={loaderCtx?.showLoad} />
    </>
  );
}

export default App;
