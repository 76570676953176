import { DocumentData } from "@firebase/firestore";
import React, { useContext, useEffect, useState, FC } from "react";
import { AuthContext } from "../../context/AuthContext";
import { GetDocsSentData } from "../../firebase/controller/dashboardController";
import { DateFormater } from "../../utils/formatHelper";
import {
  CheckIcon,
  XIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import EmptyState from "./EmptyState";
import Fuse from "fuse.js";
import { LoaderContext } from "../../context/LoaderContext";

interface Props {
  search: string;
}

const SentTable: FC<Props> = ({ search }) => {
  const { user } = useContext(AuthContext);
  const loaderCtx = useContext(LoaderContext);

  const [docs, setDocs] = useState<DocumentData[]>([]);
  const [searchDocs, setSearchDocs] = useState<DocumentData[]>([]);
  const [allDocs, setAllDocs] = useState<DocumentData[]>([]);
  const [page, setPage] = useState(1);
  const [pageCheck, setPageCheck] = useState(1);
  const [lastIndexShown, setLastIndexShown] = useState(0);
  const [firstIndexShown, setFirstIndexShown] = useState(0);

  const [totalPage, setTotalPage] = useState(0);

  const fuseOptions = {
    keys: ["title"],
  };

  const fuse = new Fuse(allDocs, fuseOptions);

  const history = useHistory();
  useEffect(() => {
    loaderCtx?.showLoader();

    GetDocsSentData(user?.uid)
      .then((docData) => {
        setDocs(docData.slice(0, 7));
        setLastIndexShown(7);
        setFirstIndexShown(0);
        setAllDocs(docData);
        setTotalPage(Math.ceil(docData.length / 7));
      })
      .finally(() => {
        loaderCtx?.hideLoader();
      });

    return () => {
      setDocs([]);
      setLastIndexShown(0);
      setFirstIndexShown(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  useEffect(() => {
    if (!search || (search === "" && allDocs)) {
      setDocs(allDocs?.slice(0, 7));
      setTotalPage(Math.ceil(allDocs?.length / 7));
      setLastIndexShown(7);
      setFirstIndexShown(0);
      return;
    }
    const searchedDocs = fuse.search(search).map((i) => i.item);

    if (searchedDocs?.length === 0) return;
    setDocs(searchedDocs?.slice(0, 7));
    setSearchDocs(searchedDocs);
    setPage(1);
    setLastIndexShown(7);
    setFirstIndexShown(0);
    setTotalPage(Math.ceil(searchedDocs?.length / 7));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (page > pageCheck) {
      HandleNextPageClick();
      setPageCheck(page);
    } else if (page < pageCheck) {
      HandlePrevPageClick();
      setPageCheck(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const HandleNextPageClick = () => {
    const nextMaxIndex = page * 7;
    if (search || search !== "") {
      setDocs(searchDocs?.slice(lastIndexShown, nextMaxIndex));
    } else {
      setDocs(allDocs?.slice(lastIndexShown, nextMaxIndex));
    }
    setFirstIndexShown(lastIndexShown);
    setLastIndexShown(nextMaxIndex);
  };

  const HandlePrevPageClick = () => {
    const nextMaxIndex = page * 7;
    if (search || search !== "") {
      setDocs(searchDocs?.slice(firstIndexShown - 7, nextMaxIndex));
    } else {
      setDocs(allDocs?.slice(firstIndexShown - 7, nextMaxIndex));
    }
    setFirstIndexShown(firstIndexShown - 7);
    setLastIndexShown(nextMaxIndex);
  };

  if (loaderCtx?.showLoad) {
    return <> </>;
  }

  if (!docs || docs?.length === 0) {
    return <EmptyState state="sent" />;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow-md dark:border-gray-800 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-800">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-200"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-200"
                  >
                    Recipients
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-200"
                  >
                    Due
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-200"
                  >
                    Validation
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-200"
                  >
                    Opened
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-200"
                  >
                    Completion
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-200"
                  >
                    Sent/Edited
                  </th>
                </tr>
              </thead>
              <tbody>
                {docs?.map((doc: any, docIdx: any) => (
                  <tr
                    key={doc.id}
                    className={classNames(
                      "hover:cursor-pointer",
                      docIdx % 2 === 0
                        ? "bg-white dark:bg-gray-900"
                        : "bg-gray-50 dark:bg-gray-800"
                    )}
                    onClick={() => history.push(`document/${doc.id}`)}
                  >
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200">
                      {doc.title}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200">
                      {doc.recipients}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200">
                      {DateFormater(doc.dueDate)}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200">
                      {doc.challengeQuestion ? (
                        <CheckIcon className="h-6 w-6 stroke-current text-green-500" />
                      ) : (
                        <XIcon className="h-6 w-6 stroke-current text-red-500" />
                      )}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200">
                      {doc.hasOpen}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200">
                      {doc.hasRead}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200">
                      {DateFormater(doc.createdAt)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="w-xfit mt-4 flex flex-row items-center justify-center self-end justify-self-center">
        {page > 1 && (
          <ChevronLeftIcon
            className="h-6 w-6"
            onClick={(e) => {
              e.preventDefault();
              setPage(page - 1);
            }}
          />
        )}
        <span>
          Page: {page} of {totalPage}
        </span>
        {page !== totalPage && (
          <ChevronRightIcon
            className="h-6 w-6"
            onClick={(e) => {
              e.preventDefault();
              setPage(page + 1);
            }}
          />
        )}
      </div>
    </div>
  );
};
export default SentTable;
