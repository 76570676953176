import { collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "../firebase";

const checkIfUserNameisValid = async (username: string): Promise<boolean> => {
  const q = query(collection(db, "users"), where("username", "==", username));
  const querySnapShot = await getDocs(q);

  return querySnapShot.empty;
};

const checkIfEmailIsInDB = async (
  email: string | null
): Promise<string | undefined> => {
  let uid: string | undefined = undefined;
  if (!email) return undefined;
  const q = query(collection(db, "users"), where("email", "==", email));
  const querySnapShot = await getDocs(q);

  querySnapShot.forEach((doc) => (uid = doc.data().id));

  return uid;
};

const hasNumber = (str: string) => {
  const hasNum: RegExp = /\d/;

  return hasNum.test(str);
};

export { checkIfEmailIsInDB, checkIfUserNameisValid, hasNumber };
