import { XIcon } from "@heroicons/react/outline";
import Modal from "react-modal";
import React, { FC, SyntheticEvent, useContext, useState } from "react";
import Button from "../components/lib/Button";
import * as EmailValidator from "email-validator";
import { sendPasswordResetEmail } from "@firebase/auth";
import { auth } from "../firebase";
import { NotificationContext } from "../context/NotificationContext";

interface Props {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
}

const ForgotPassModal: FC<Props> = ({ showModal, setShowModal }) => {
  const [email, setEmail] = useState("");

  const notifactionCtx = useContext(NotificationContext);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (EmailValidator.validate(email)) {
      setShowModal(false);
      sendPasswordResetEmail(auth, email).then(() => {
        notifactionCtx?.DisplayMessage(
          "success",
          "Check Email for Reset Email Link"
        );
      });
    } else {
      notifactionCtx?.DisplayMessage("error", "Email Input in not valid");
    }
  };

  const rootEl = document.getElementById("root");
  if (!rootEl) {
    return <> </>;
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        style={customStyles}
        onRequestClose={() => {
          setShowModal(false);
          return true;
        }}
        appElement={rootEl}
      >
        <div className="flex h-full w-full flex-col">
          <div className="h-fit w-fit self-end">
            <Button variant="circle" size="xs">
              <XIcon className="h-5 w-5" onClick={() => setShowModal(false)} />
            </Button>
          </div>
          <div className="mx-auto my-2 w-fit">
            <span className="text-center text-xl">Forgot Password</span>
          </div>

          <div className="mx-auto mt-3 w-64">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@email.com"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-5 flex items-center justify-end space-x-2 justify-self-end">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button size="sm" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ForgotPassModal;
