import React, { PropsWithChildren } from "react";
import classnames from "classnames";

type Props = JSX.IntrinsicElements["button"] & {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  variant?: "default" | "secondary" | "outlined" | "outlined2" | "circle";
  fullWidth?: boolean;
};

export default function Button(props: PropsWithChildren<Props>) {
  const {
    children,
    size = "md",
    variant = "default",
    fullWidth = false,
    className,
    ...rest
  } = props;

  const variants = {
    default:
      "inline-flex items-center border border-transparent font-medium rounded-md shadow-sm text-white bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-400",
    secondary:
      "inline-flex items-center border border-transparent font-medium rounded-md text-purple-700 bg-purple-200 hover:bg-purple-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500",
    outlined:
      "inline-flex items-center border border-purple-600 shadow-sm font-medium rounded-md text-purple-600 bg-transparent hover:bg-purple-200 hover:bg-opacity-100 dark:hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500",
    outlined2:
      "inline-flex items-center border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500",
    circle:
      "inline-flex items-center border border-transparent rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500",
  };

  const sizes = {
    normal: {
      xs: "px-2.5 py-1.5 text-xs",
      sm: "px-3 py-2 text-sm",
      md: "px-4 py-2 text-sm",
      lg: "px-4 py-2 text-base",
      xl: "px-6 py-3 text-base",
    },
    circle: {
      xs: "p-1",
      sm: "p-1.5",
      md: "p-2",
      lg: "p-2",
      xl: "p-3",
    },
  };

  function getSizes() {
    if (variant === "circle") return sizes.circle[size];
    return sizes.normal[size];
  }

  return (
    <>
      <button
        type="button"
        className={classnames(variants[variant], getSizes(), className, {
          "w-full justify-center": fullWidth,
        })}
        {...rest}
      >
        {children}
      </button>{" "}
    </>
  );
}
