/* This example requires Tailwind CSS v2.0+ */
import { FC, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

export const types = [
  { id: 0, type: "Text", value: "text" },
  { id: 1, type: "File (pdf)", value: "file" },
  { id: 2, type: "Image (jpg, png)", value: "image" },
  { id: 3, type: "Video (Youtube/Loom Link)", value: "video" },
  { id: 4, type: "Shared or Public Google Doc (Link)", value: "doc" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export interface type {
  id: number;
  type: string;
  value: string;
}

interface Props {
  contentType: type;
  setContentType: (selected: type) => void;
}

const ContentTypeInput: FC<Props> = ({ contentType, setContentType }) => {
  return (
    <Listbox value={contentType} onChange={setContentType}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Content Type
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-purple-500 focus:outline-none focus:ring-1 focus:ring-purple-500 dark:border-gray-800 dark:bg-gray-600 dark:text-gray-200 sm:text-sm">
              <span className="block truncate">{contentType.type}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-600 dark:text-gray-200 sm:text-sm">
                {types.map((type) => (
                  <Listbox.Option
                    key={type.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-purple-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9 dark:text-gray-200"
                      )
                    }
                    value={type}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {type.type}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-purple-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default ContentTypeInput;
