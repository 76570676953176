import React, { FC } from "react";
import classNames from "classnames";
import Toggle from "../lib/Toggle";

interface Props {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  challengeQ: string;
  setChallengeQ: (challengeQ: string) => void;
}

const ChallengeQuestionInput: FC<Props> = ({
  enabled,
  setEnabled,
  challengeQ,
  setChallengeQ,
}) => {
  return (
    <div>
      <div className="flex items-end justify-between">
        <label
          htmlFor="question"
          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
        >
          Challenge Question
        </label>
        <Toggle enabled={enabled} setEnabled={setEnabled} />
      </div>
      <div className="mt-1">
        <input
          disabled={!enabled}
          type="text"
          name="question"
          id="question"
          className={classNames(
            "w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 dark:border-gray-900 dark:text-gray-200 dark:focus:border-purple-500  dark:focus:ring-purple-500 sm:text-sm",
            {
              "cursor-pointer bg-gray-200 opacity-70 dark:bg-gray-300 dark:placeholder-gray-700":
                !enabled,
            },
            { "bg-white dark:bg-gray-600": enabled }
          )}
          placeholder="Summarize what this article means to you"
          onChange={(e) => setChallengeQ(e.target.value)}
        />
      </div>
    </div>
  );
};

export default ChallengeQuestionInput;
