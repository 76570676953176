import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useLocation } from "react-router-dom";

const Logo = () => {
  const { user } = useContext(AuthContext);

  const location = useLocation();
  if (
    (!user && location.pathname === "/") ||
    location.pathname === "/login" ||
    location.pathname === "/signup"
  ) {
    return <> </>;
  }
  return (
    <div className="absolute left-1/2 mt-2 -translate-x-1/2">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/whoseenit.appspot.com/o/images%2FBranding%2FWords.png?alt=media&token=85ecf30e-9cb9-4a30-968c-b7da65d0f25a"
        className="mx-auto w-56"
        alt="logo"
      />
    </div>
  );
};

export default Logo;
