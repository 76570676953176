import React, { FC } from "react";
import { PaperAirplaneIcon, InboxInIcon } from "@heroicons/react/outline";

interface Props {
  tab: string;
  setTab: (tab: string) => void;
}

const Tabs: FC<Props> = ({ tab, setTab }) => {
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div>
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <div
              onClick={() => setTab("sent")}
              className={classNames(
                tab === "sent"
                  ? "border-purple-500 text-purple-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-200",
                "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
              )}
            >
              <PaperAirplaneIcon
                className={classNames(
                  tab === "sent"
                    ? "text-purple-500"
                    : "text-gray-400 group-hover:text-gray-500 dark:text-gray-200",
                  "-ml-0.5 mr-2 h-5 w-5"
                )}
                aria-hidden="true"
              />
              <span>Sent</span>
            </div>
            <div
              onClick={() => setTab("received")}
              className={classNames(
                tab === "received"
                  ? "border-purple-500 text-purple-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-200",
                "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
              )}
            >
              <InboxInIcon
                className={classNames(
                  tab === "received"
                    ? "text-purple-500"
                    : "text-gray-400 group-hover:text-gray-500 dark:text-gray-200",
                  "-ml-0.5 mr-2 h-5 w-5"
                )}
                aria-hidden="true"
              />
              <span>Received</span>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
