import {
  collection,
  doc,
  addDoc,
  getDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, logClient } from "../../firebase";

export type documentType = {
  author: string | null | undefined;
  challengeQuestion?: string;
  title: string;
  status: "draft" | "published";
  content: string | FileList | null;
  dueDate: Timestamp;
  recipients: number;
  contentType: string;
};

const document = collection(db, "document");

export const AddDocument = async (newDoc: documentType) => {
  try {
    const docRef = await addDoc(document, {
      ...newDoc,
      createdAt: serverTimestamp(),
    });
    logClient.log("Document written with ID: ", docRef.id);
    return docRef;
  } catch (error) {
    logClient.warn(error);
  }
};
export const UpdateDocument = async (docId: string, data: any) => {
  try {
    const docRef = doc(db, "document", docId);
    await setDoc(
      docRef,
      {
        timestamp: serverTimestamp(),
        ...data,
      },
      { merge: true }
    );
    logClient.log(`Document with id: ${docId} updated`);
  } catch (error) {
    logClient.warn(error);
  }
};
//get
export const GetDocument = async (docId: string) => {
  const docRef = doc(db, "document", docId);

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
    throw new Error("Not found in db");
  } catch (error) {
    logClient.warn(error);
  }
};

export const DeletDocument = async (docId: string) => {
  const docRef = doc(db, "document", docId);
  try {
    await deleteDoc(docRef);
    logClient.log(`Doc with id: ${docRef.id} deleted`);
  } catch (error) {
    logClient.warn(error);
  }
};

export const GetDocTitleAndDueDate = async (docId: string) => {
  const docRef = doc(db, "document", docId);
  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const title = docSnap.get("title");
      const dueDate = docSnap.get("dueDate");
      const createdAt = docSnap.get("createdAt");

      return { title, dueDate, createdAt };
    }
    throw new Error(`Docuemnt not Found with Id: ${docId}`);
  } catch (error) {
    logClient.warn(error);
  }
};
