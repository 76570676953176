import { SearchIcon } from "@heroicons/react/outline";
import React, { FC } from "react";

interface Props {
  setSearch: (search: string) => void;
}
const SearchInput: FC<Props> = ({ setSearch }) => {
  return (
    <div className="relative mt-1 rounded-md shadow-sm">
      <input
        type="text"
        name="account-number"
        id="account-number"
        className="dark:boarder-gray-800 block w-full rounded-md border-gray-300 bg-gray-50 pr-10 focus:border-purple-500 focus:ring-purple-500 dark:border-gray-800 dark:bg-gray-600 dark:text-gray-200 dark:placeholder-gray-200 sm:text-sm"
        placeholder="Search Memo"
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <SearchIcon className="h-5 w-5 text-gray-400 dark:text-gray-200" />
      </div>
    </div>
  );
};

export default SearchInput;
