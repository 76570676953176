import React, { FC } from "react";
import { formatChallengeQuestion } from "../../utils/formatHelper";

interface Props {
  data: any;
}

const AuthorUserView: FC<Props> = ({ data }) => {
  return (
    <>
      <div className="grid auto-rows-min space-y-8 divide-y-2 divide-solid divide-gray-500 divide-opacity-40 dark:divide-purple-400 dark:text-gray-200">
        <div className="flex h-fit flex-col">
          <div className="!mt-6 grid grid-cols-2">
            <span className="text-md font-bold">Sent</span>
            <span className="text-md text-center">Jan 14</span>
          </div>
          <div className="grid grid-cols-2">
            <span className="text-md font-bold">Due</span>
            <span className="text-md text-center">Jan 21</span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-md !mt-6 font-bold">Challenge Question</span>
          <span className="text-md">
            {formatChallengeQuestion(data?.doc.challengeQuestion) || "N/A"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-md !mt-6 font-bold">Response</span>
          <span className="text-md">
            {data?.user.reflection || "No response yet!"}
          </span>
        </div>
      </div>
    </>
  );
};

export default AuthorUserView;
