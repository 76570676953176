export const formatChallengeQuestion = (challengeQ: string) => {
  if (!challengeQ) {
    return;
  }
  if (!(challengeQ.charAt(-1) === "?")) return challengeQ + "?";

  return challengeQ;
};

export const DateFormater = (date: any) => {
  return date?.toDate().toLocaleDateString("en-us", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};
