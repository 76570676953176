import React, {
  FC,
  SyntheticEvent,
  useEffect,
  useState,
  useContext,
} from "react";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import Button from "../components/lib/Button";
import "react-datepicker/dist/react-datepicker.css";
import { GetAllUserNames } from "../firebase/controller/userDocController";
import { DocumentData, Timestamp } from "@firebase/firestore";
import { CreateDoc } from "../firebase/controller/createController";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import {
  ChallengeQuestionInput,
  TitleInput,
  UserInput,
  ContentInput,
  DueDateInput,
} from "../components/create";
import ContentTypeInput, { types } from "../components/create/ContentTypeInput";
import { AddUser } from "../firebase/controller/userController";
import { NotificationContext } from "../context/NotificationContext";
import { sendEmailVerification } from "@firebase/auth";

const Create: FC = (props) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState<string | FileList | null>("");
  const [dueDate, setDueDate] = useState<any>(new Date());
  const [challengeQ, setChallengeQ] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [users, setUsers] = useState<DocumentData>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [contentType, setContentType] = useState<any>(types[0]);

  const { user } = useContext(AuthContext);
  const notificationCxt = useContext(NotificationContext);

  const history = useHistory();

  useEffect(() => {
    GetAllUserNames().then((data) => {
      setUsers(data.filter((u) => u.id !== user?.uid));
    });
  }, [user?.uid]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (user && !user?.emailVerified) {
      notificationCxt?.DisplayMessage(
        "error",
        "Email must be verified. Check Email."
      );
      sendEmailVerification(user);
      return;
    }
    if (!title) {
      notificationCxt?.DisplayMessage("error", "Title cannot be empty!");
      return;
    } else if (!content) {
      notificationCxt?.DisplayMessage("error", "Content cannot be empty!");
      return;
    } else if (!selectedUsers || selectedUsers.length === 0) {
      notificationCxt?.DisplayMessage(
        "error",
        "Please select at least 1 user!"
      );
      return;
    }

    const selectedUsersObject: any = {};

    for (const user of selectedUsers) {
      if (user.__isNew__) {
        const id = await AddUser({
          email: user.value,
          userVerified: false,
        });

        if (id) selectedUsersObject[id] = user.value;
        continue;
      }

      selectedUsersObject[user.value] = user.label;
    }

    const TimestampDueDate = Timestamp.fromDate(dueDate);

    const docId = await CreateDoc({
      author: user?.uid,
      title,
      content,
      selectedUsersObject,
      challengeQ,
      contentType,
      dueDate: TimestampDueDate,
    });

    notificationCxt?.DisplayMessage("success", "Memo Created Successfully");

    history.push(`document/${docId}`);
  };

  if (!users) return <> </>;
  return (
    <>
      <div className="mt-2 w-full pt-10 md:mx-8 md:mt-4">
        <div className="flex h-fit items-center justify-between">
          <div className="flex content-center">
            <ArrowSmLeftIcon
              onClick={() => history.push("dashboard")}
              className="mr-4 h-6 w-6 stroke-current text-gray-700 hover:text-purple-500 dark:text-gray-300 dark:hover:text-purple-500"
            />
            <span className="text-gray-400">{title || "Untitled Memo"} </span>
          </div>
        </div>
        <div className="mx-auto mt-4 w-80 rounded-lg bg-white py-8 px-10 shadow dark:bg-gray-800 sm:w-96">
          <form onSubmit={handleSubmit} className="flex flex-col space-y-3">
            <TitleInput title={title} setTitle={setTitle} />
            <ContentTypeInput
              contentType={contentType}
              setContentType={setContentType}
            />
            <ContentInput
              content={content}
              setContent={setContent}
              contentType={contentType}
            />
            <DueDateInput dueDate={dueDate} setDueDate={setDueDate} />
            <ChallengeQuestionInput
              enabled={enabled}
              setEnabled={setEnabled}
              challengeQ={challengeQ}
              setChallengeQ={setChallengeQ}
            />

            <UserInput
              users={users}
              setUsers={setUsers}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
            <Button type="submit" className="!mt-6" fullWidth variant="default">
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Create;
