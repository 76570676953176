import React, { FC, useContext } from "react";
import { StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import { ThemeContext } from "../../context/ThemeContext";
import * as EmailValidator from "email-validator";

const SelectDropdown: FC<any> = ({
  users,
  selectedUsers,
  setSelectedUsers,
  setUsers,
}) => {
  const theme = useContext(ThemeContext);

  const getBgColor = () => {
    return theme?.theme === "dark" ? "#4B5563" : "white";
  };

  const getBorderColor = () => {
    return theme?.theme === "dark" ? "#111827" : "#D1D5DB";
  };

  const getIndicatorColor = () => {
    return theme?.theme === "dark" ? "#E5E7EB" : "#4B5563";
  };

  const getInputTextColor = () => {
    return theme?.theme === "dark" ? "#F9FAFB" : "#111827";
  };

  const handleChange = (selectedUser: any) => {
    setSelectedUsers(selectedUser);
  };

  const checkNewOption = (input: string) => {
    return EmailValidator.validate(input);
  };

  const colorStyles: StylesConfig<any, true> = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: getBgColor(),
      borderColor: isFocused ? "#9061F9 !important" : getBorderColor(),
      boxShadow: "none",
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: "white",
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        color: getIndicatorColor(),
      };
    },
    placeholder: (style) => {
      return {
        ...style,
        fontSize: "0.875rem",
        color: "#9ca3af",
      };
    },
    input: (style) => {
      return {
        ...style,
        color: getInputTextColor(),
      };
    },
  };

  return (
    <CreatableSelect
      isMulti={true}
      options={users}
      isClearable
      isSearchable
      formatCreateLabel={(userInput) => `Add ${userInput}`}
      isValidNewOption={checkNewOption}
      name="userselect"
      styles={colorStyles}
      onChange={handleChange}
    />
  );
};

export default SelectDropdown;
