import { ThemeProvider } from "./ThemeContext";
import { NotificationProvider } from "./NotificationContext";
import { AuthProvider } from "./AuthContext";
import { combineComponents } from "../utils/combineComponents";
import { LoaderProvider } from "./LoaderContext";

const providers = [
  ThemeProvider,
  NotificationProvider,
  AuthProvider,
  LoaderProvider,
];

export const AppContextProvider = combineComponents(...providers);
