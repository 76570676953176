import {
  collection,
  DocumentData,
  getDocs,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { db } from "../../firebase";
import { GetDocTitleAndDueDate } from "./docController";
import { GetDocRates } from "./userDocController";

export const GetDocsReceivedData = async (userId: string | undefined) => {
  const userDocColl = collection(db, "UserDocRelation");

  const q = query(
    userDocColl,
    where("userId", "==", userId),
    orderBy("lastUpdated", "desc")
  );

  const querySnapshot = await getDocs(q);

  const userDocData: DocumentData[] = [];

  querySnapshot.forEach((userDoc) => {
    userDocData.push(userDoc.data());
  });

  const res = await Promise.all(
    userDocData.map(async (userDoc) => {
      const { title, dueDate, createdAt }: any = await GetDocTitleAndDueDate(
        userDoc.docId
      );

      return { ...userDoc, title, dueDate, createdAt };
    })
  );
  return res;
};

export const GetDocsSentData = async (userId: string | undefined) => {
  //Collection
  const document = collection(db, "document");

  //Queries
  const q1 = query(
    document,
    where("author", "==", userId),
    orderBy("createdAt", "desc")
  );

  //data
  const documentSnapShot = await getDocs(q1);

  const documentData: DocumentData[] = [];

  documentSnapShot.forEach((doc) => {
    const docData = { ...doc.data(), id: doc.id };
    documentData.push(docData);
  });

  const res = await Promise.all(
    documentData.map(async (doc) => {
      const { hasRead, hasOpen }: any = await GetDocRates(doc.id);

      return { ...doc, hasOpen, hasRead };
    })
  );

  return res;
};
