import {
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  getDoc,
  QueryDocumentSnapshot,
  DocumentData,
} from "@firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  signOut,
  signInWithRedirect,
  GoogleAuthProvider,
  GithubAuthProvider,
  getRedirectResult,
  sendEmailVerification,
} from "firebase/auth";
import { checkIfEmailIsInDB } from "../../utils/loginHelper";
import { documentProfile } from "../controller/docProfController";
import { CreateNewUser } from "../controller/FunctionsController";
import { GetUser } from "../controller/userController";
import { userDocRelationColl } from "../controller/userDocController";
import { auth, db, logClient } from "../../firebase";

export async function SignUp(email: string, password: string, name: string) {
  //if email exist in db if its does call new function and if it doesn't run the other stuff

  const uid = await checkIfEmailIsInDB(email);

  if (uid) {
    await CreateNewUser({ email, password, uid, name });
    await updateDoc(doc(db, "users", uid), {
      username: name,
      userVerified: true,
    });
    await signInWithEmailAndPassword(auth, email, password);
    return;
  }

  await createUserWithEmailAndPassword(auth, email, password).then(
    async (userCredential) => {
      const user = userCredential.user;
      await sendEmailVerification(user);
      await setDoc(doc(db, "users", user.uid), {
        username: name,
        isVerified: false,
        id: user.uid,
        email: user.email,
      });
      await updateProfile(user, { displayName: name });
    }
  );
}

export async function Login(email: string, password: string) {
  await signInWithEmailAndPassword(auth, email, password);
}

export async function HandleGoogleLogin() {
  const provider = new GoogleAuthProvider();
  signInWithRedirect(auth, provider);
}

export async function HandleGithubLogin() {
  const provider = new GithubAuthProvider();
  signInWithRedirect(auth, provider);
}

export async function GetRedirectResult() {
  await getRedirectResult(auth)
    .then(async (result) => {
      if (result) {
        // The signed-in user info.
        const user = result.user;
        const userInDb = await GetUser(user.uid);
        //check if exist in db with old uid
        const uid = await checkIfEmailIsInDB(user.email);

        if (uid && uid !== user.uid) {
          //replace uid with user.uid in a transaction
          try {
            const batch = writeBatch(db);

            const userDocQuery = query(
              userDocRelationColl,
              where("userId", "==", uid)
            );
            const docProfQuery = query(
              documentProfile,
              where(`userIds.${uid}`, ">", "")
            );
            const oldUserRef = doc(db, "users", uid);
            const NewUserRef = doc(db, "users", user.uid);

            const oldUserSnap = await getDoc(oldUserRef);

            if (!oldUserSnap.exists()) {
              throw new Error("User not in database");
            }
            const userDocQuerySnap = await getDocs(userDocQuery);
            const docProfQuerySnap = await getDocs(docProfQuery);

            const tempDocs: QueryDocumentSnapshot<DocumentData>[] = [];

            docProfQuerySnap.forEach((document) => {
              tempDocs.push(document);
            });

            for (const document of tempDocs) {
              const docProfRef = doc(db, "docProfile", document.id);

              const docProfSnap = await getDoc(docProfRef);

              if (!docProfSnap.exists()) {
                throw new Error("Doesn't exist");
              }

              const userIds = docProfSnap.data().userIds;

              userIds[user.uid] = userIds[uid];
              delete userIds[uid];

              batch.update(docProfRef, { userIds });
            }

            userDocQuerySnap.forEach((document) => {
              const userDocRef = doc(db, "UserDocRelation", document.id);
              batch.update(userDocRef, {
                userId: user.uid,
              });
            });

            batch.set(NewUserRef, { ...oldUserSnap.data(), id: user.uid });
            batch.delete(oldUserRef);
            batch.commit();
            logClient.log("Batch Write successfully committed!");
          } catch (e) {
            logClient.log("Batch failed: ", e);
          }
        }

        //create a db doc if first time signing up
        if (!userInDb && !uid) {
          await setDoc(doc(db, "users", user.uid), {
            username: user.displayName,
            isVerified: false,
            id: user.uid,
            email: user.email,
          });
        }
      }
      // This gives you a Google Access Token. You can use it to access Google APIs.
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;

      logClient.warn(errorCode, errorMessage);
      // The email of the user's account used.
      // const email = error.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
    });
}

export async function Logout() {
  await signOut(auth).catch((error) => {
    logClient.warn(error);
  });
}
