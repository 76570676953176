import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import Button from "../components/lib/Button";
import { AuthContext } from "../context/AuthContext";
import "animate.css";

export default function Home() {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) history.push("dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="mx-auto h-screen w-screen bg-gradient-to-tr from-amber-400 to-pink-400 md:pr-10 md:pl-5">
      <div className="grid h-12 grid-cols-1 items-center md:grid-cols-3">
        <div />
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/whoseenit.appspot.com/o/images%2FBranding%2FWhiteWhoTransparent.png?alt=media&token=6d50dcb7-f394-4fa1-babd-e7d16ab407af"
          }
          className="mx-auto mt-2 w-1/2 md:w-80"
          alt="Written Logo"
        />
      </div>
      <div className="flex h-full items-center md:h-1/2">
        <div className="mx-auto grid grid-cols-1 md:grid-cols-12">
          <div className="mb-20 flex flex-col text-center font-sans font-light text-slate-50 md:col-span-8 md:mt-20 md:pl-8 md:text-left">
            <h1 className="text-5xl md:text-6xl">
              Who&nbsp;
              <span className="whitespace-nowrap text-purple-500 ">
                Seen It
              </span>
              ?
            </h1>
            <p className="mt-10 text-2xl">
              Make sure your documents are read by those who need to read them
            </p>
            <p className="mt-5 text-2xl md:mt-0">
              Upload Anything from Videos to Google Docs and assign them to
              readers
            </p>
            <div className="mx-auto mt-5 h-fit">
              <Button
                variant="default"
                size="lg"
                onClick={() => history.push("/login")}
              >
                Login
              </Button>
              <Button
                variant="default"
                size="lg"
                onClick={() => history.push("/signup")}
              >
                Sign Up
              </Button>
            </div>
          </div>
          <div className="col-span-4 md:mt-2">
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/whoseenit.appspot.com/o/images%2FBranding%2FMainLogoTransparent.png?alt=media&token=83f717b4-d24d-4e69-b139-93f0fd4864a8"
              }
              alt="Main Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
