import React, { createContext, FC, useState } from "react";

interface LoaderContextInterface {
  showLoad: boolean;
  showLoader: () => void;
  hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextInterface | null>(null);

const LoaderProvider: FC = ({ children }) => {
  const [showLoad, setShowLoad] = useState(false);

  const showLoader = () => {
    setShowLoad(true);
  };

  const hideLoader = () => {
    setShowLoad(false);
  };

  const value = {
    showLoad,
    showLoader,
    hideLoader,
  };

  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};

export { LoaderProvider, LoaderContext };
