import React, { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import GoogleDocEmbed from "./GoogleDocEmbed";
import LoomEmbed from "./LoomEmbed";
import PdfViewer from "./PdfViewer";

interface Props {
  doc: any;
}

const Content: FC<Props> = ({ doc }) => {
  const [loomId, setLoomId] = useState("");
  const [googleDocUrl, setGoogleDocUrl] = useState("");

  useEffect(() => {
    if (
      doc?.contentType === "video" &&
      (doc.content as string).includes("loom")
    ) {
      //loom url
      const regex: RegExp = /\b[\w=.]+$/;

      const match = regex.exec(doc.content);
      if (match) setLoomId(match[0]);
    }
    if (doc?.contentType === "doc") {
      setGoogleDocUrl(
        (doc?.content as string).replace("edit", "preview") + "?embedded=false"
      );
    }
  }, [doc]);
  return (
    <>
      {doc?.contentType === "text" && <span> {doc.content}</span>}
      {doc?.contentType === "image" && (
        <img src={doc?.content} alt="Uploaded Content" />
      )}
      {doc?.contentType === "file" && <PdfViewer doc={doc?.content} />}
      {doc?.contentType === "doc" && <GoogleDocEmbed url={googleDocUrl} />}
      {doc?.contentType === "video" && (
        <>
          {loomId ? (
            <LoomEmbed loomId={loomId} />
          ) : (
            <ReactPlayer
              controls={true}
              url={doc?.content}
              className="mx-auto"
            />
          )}
        </>
      )}
    </>
  );
};

export default Content;
