import React, { createContext, FC, useEffect, useState } from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

interface AuthContextInterFace {
  user: User | null;
  authenticated: boolean;
  setUser: (user: User | null) => void;
  loadingAuthState: boolean;
}

const AuthContext = createContext<Partial<AuthContextInterFace>>({});

const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoadingAuthState(false);
    });
    return unsubscribe;
  });

  const authValue = {
    user,
    authenticated: user !== null,
    setUser,
    loadingAuthState,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
