import React, { SyntheticEvent } from "react";
import GitHubSignIn from "./GithubSignin";
import GoogleSignin from "./GoogleSignin";

import {
  HandleGoogleLogin,
  HandleGithubLogin,
} from "../../firebase/auth/AuthHelper";
import { useHistory } from "react-router";

const SocialButtons = () => {
  const history = useHistory();
  const handleGoogleClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await HandleGoogleLogin();
      history.push("/dashboard");
    } catch (error) {}
  };

  const handleGitHubClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await HandleGithubLogin();
      history.push("/dashboard");
    } catch (error) {}
  };
  return (
    <div className="mt-6 grid grid-cols-2 gap-3">
      <div className="hover:cursor-pointer" onClick={handleGoogleClick}>
        <GoogleSignin />
      </div>

      <div className="hover:cursor-pointer" onClick={handleGitHubClick}>
        <GitHubSignIn />
      </div>
    </div>
  );
};

export default SocialButtons;
