import React, { useState } from "react";
import Tabs from "../components/dashboard/Tabs";
import { PlusSmIcon } from "@heroicons/react/outline";
import Button from "../components/lib/Button";
import SentTable from "../components/dashboard/SentTable";
import ReceivedTable from "../components/dashboard/ReceivedTable";
import { useHistory } from "react-router";
import SearchInput from "../components/dashboard/SearchInput";

const Dashboard = () => {
  const history = useHistory();
  const [tab, setTab] = useState("sent");
  const [search, setSearch] = useState("");

  return (
    <>
      <div className="relative h-full w-full overflow-x-hidden p-10">
        <div className="mx-auto mt-3 w-fit md:mx-0 md:mt-0">
          <span className="text-center font-sans text-4xl font-light dark:text-gray-200">
            Memos
          </span>
        </div>
        <div className="mt-5 flex flex-col-reverse items-center justify-between space-y-3 md:mt-10 md:flex-row">
          <Tabs tab={tab} setTab={setTab} />
          <div className="flex items-center justify-center space-x-3">
            <SearchInput setSearch={setSearch} />
            <Button
              variant="default"
              size="xs"
              className="!pl-1.5 !pr-2.5"
              onClick={() => history.push("create")}
            >
              <PlusSmIcon className="h-5 w-5" />
              <span>Create New</span>
            </Button>
          </div>
        </div>
        <div className="mt-10">
          {tab === "sent" && <SentTable search={search} />}
          {tab === "received" && <ReceivedTable search={search} />}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
