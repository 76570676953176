import { PlusIcon } from "@heroicons/react/outline";
import React, { FC } from "react";
import { useHistory } from "react-router";
import Button from "../lib/Button";

interface Props {
  state: string;
}

const EmptyState: FC<Props> = ({ state }) => {
  const history = useHistory();
  return (
    <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-200"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-50">
        No {state} Memos
      </h3>
      {state === "sent" && (
        <>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Get started by creating a new memos.
          </p>
          <div className="mt-6">
            <Button
              variant="default"
              size="lg"
              onClick={() => history.push("create")}
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" />
              <span>Create New</span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EmptyState;
