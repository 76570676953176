import React, { SyntheticEvent, useContext, useState } from "react";
import { useHistory } from "react-router";
import { NotificationContext } from "../../context/NotificationContext";
import { Login } from "../../firebase/auth/AuthHelper";
import EmailInput from "../lib/EmailInput";
import PasswordInput from "../lib/PasswordInput";
import SocialButtons from "./SocialButtons";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import ForgotPassModal from "../../modals/ForgotPassModal";

export default function Loginform() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPassModal, setShowForgotPassModal] = useState(false);

  const history = useHistory();

  const notifactionCtx = useContext(NotificationContext);

  const handleSubmit = async (e: SyntheticEvent) => {
    //check if pass is strong check if 8 contains and number
    e.preventDefault();
    if (!email || !password) {
      return;
    }
    try {
      await Login(email, password);
      history.push("/dashboard");
    } catch (err) {
      notifactionCtx?.DisplayMessage(
        "error",
        "Somethings not right! Check Email and Password"
      );
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-[4.5rem] w-auto cursor-pointer"
            onClick={() => history.push("/")}
            src="https://firebasestorage.googleapis.com/v0/b/whoseenit.appspot.com/o/images%2FBranding%2FIconTransparent.png?alt=media&token=01f7c0ff-f735-416c-b04b-b2fe45df0383"
            alt="Logo"
          />
          <div className="mt-6 flex items-center justify-center">
            <ArrowLeftIcon
              className="mr-2 h-6 w-6 stroke-current hover:text-purple-500 dark:text-slate-50 dark:hover:text-purple-500"
              onClick={() => history.push("/")}
            />
            <h2 className="text-center text-3xl font-extrabold text-gray-900 dark:text-gray-100">
              Login to your account
            </h2>
          </div>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow dark:bg-gray-800 sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <EmailInput email={email} setEmail={setEmail} />
              <PasswordInput password={password} setPassword={setPassword} />

              <div className="flex items-center justify-between">
                <div
                  className="cursor-pointer text-sm font-medium text-purple-600 hover:text-purple-500"
                  onClick={() => setShowForgotPassModal(true)}
                >
                  Forgot your password?
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  Login
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500 dark:bg-gray-800 dark:text-white">
                    Or continue with
                  </span>
                </div>
              </div>
            </div>

            <SocialButtons />
            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span
                  onClick={() => history.push("signup")}
                  className="bg-white px-2 text-gray-500 hover:text-purple-500 dark:bg-gray-800 dark:text-white dark:hover:text-purple-500"
                >
                  Or create a new account!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ForgotPassModal
        showModal={showForgotPassModal}
        setShowModal={setShowForgotPassModal}
      />
    </>
  );
}
