import React, { FC, useContext } from "react";
import { Viewer, Worker, Plugin } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { ThemeContext } from "../../context/ThemeContext";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

interface Props {
  doc: string;
}

const PdfViewer: FC<Props> = ({ doc }) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const theme = useContext(ThemeContext);
  return (
    <div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js">
        <div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              display: "flex",
              justifyContent: "center",
              padding: "4px",
            }}
          >
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            <Viewer
              fileUrl={doc}
              plugins={[zoomPluginInstance as Plugin]}
              theme={theme?.theme as string}
            />
          </div>
        </div>
      </Worker>
    </div>
  );
};

export default PdfViewer;
