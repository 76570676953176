import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import { Timestamp } from "firebase/firestore";
import { type } from "../../components/create/ContentTypeInput";
import { logClient, storage } from "../../firebase";
import { AddDocument, documentType } from "./docController";
import { AddDocumentProfile } from "./docProfController";
import { SendEmailForMemo } from "./FunctionsController";
import { GetUsernameFromId } from "./userController";
import { AddUserDocRelation } from "./userDocController";

interface docCreation {
  author: string | null | undefined;
  title: string;
  challengeQ?: string;
  selectedUsersObject: any;
  dueDate: Timestamp;
  content: string | FileList | null;
  contentType: type;
}

//get datebase collections

export const CreateDoc = async (data: docCreation) => {
  if (data.contentType.value === "file") {
    const file = (data.content as FileList).item(0);
    if (file) {
      const storageRef = ref(storage, `document/${file.name}`);
      await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(storageRef);
      data.content = downloadURL;
    }
    //storage stuff
  }

  if (data.contentType.value === "image") {
    const file = (data.content as FileList).item(0);
    if (file) {
      const storageRef = ref(storage, `image/${file.name}`);
      await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(storageRef);
      data.content = downloadURL;
    }
  }

  const selectedUsersIds = Object.keys(data.selectedUsersObject);

  const docData: documentType = {
    author: data.author,
    title: data.title,
    status: "draft",
    challengeQuestion: data?.challengeQ,
    content: data.content,
    dueDate: data.dueDate,
    recipients: selectedUsersIds.length,
    contentType: data.contentType.value,
  };
  try {
    const docRef = await AddDocument(docData);

    await AddDocumentProfile({
      docId: docRef?.id,
      userIds: data.selectedUsersObject,
    });
    const senderUsername = await GetUsernameFromId(data?.author as string);

    selectedUsersIds.forEach(async (id: string) => {
      await AddUserDocRelation({
        docId: docRef?.id,
        userId: id,
        email: data.selectedUsersObject[id],
        hasOpen: false,
        hasRead: false,
        reflection: "",
      });

      const recipientUsername = await GetUsernameFromId(id);
      SendEmailForMemo({
        recipient: recipientUsername,
        sender: senderUsername,
        url: encodeURI(
          `${
            process.env.NODE_ENV !== "production"
              ? "http://localhost:3000"
              : "https://whoseenit.com"
          }/document/${docRef?.id}`
        ),
        mailTo: data.selectedUsersObject[id],
      });
    });

    return docRef?.id;
  } catch (error) {
    logClient.warn(error);
  }
};
