import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { Logger } from "@firebase/logger";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAvexeLjEoW3DDFAe5OhYM_fBQa-fdy2p4",
  authDomain: "whoseenit.firebaseapp.com",
  projectId: "whoseenit",
  storageBucket: "whoseenit.appspot.com",
  messagingSenderId: "777657675570",
  appId: "1:777657675570:web:9b5dde340c62937566b12b",
  measurementId: "G-CJDCNFF7P3",
});

export const logClient = new Logger("@firebase/logs");
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp);

if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
}
