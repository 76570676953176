import React, { createContext, FC, useState } from "react";

type NotificationType = "error" | "success" | "";

interface NotificationContextInterface {
  show: boolean;
  setShow: (value: boolean) => void;
  message: string;
  DisplayMessage: (type: NotificationType, message: string) => void;
  type: NotificationType;
}

const NotificationContext = createContext<NotificationContextInterface | null>(
  null
);

const NotificationProvider: FC = ({ children }) => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState<NotificationType>("");
  const [message, setMessage] = useState("");

  const DisplayMessage = (type: NotificationType, message: string) => {
    setShow(true);
    setType(type);
    setMessage(message);

    setTimeout(() => {
      setShow(false);
      setMessage("");
      setType("");
    }, 2500);
  };

  const value = {
    show,
    DisplayMessage,
    message,
    setShow,
    type,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, NotificationContext };
