import { ArrowLeftIcon } from "@heroicons/react/outline";
import React, { SyntheticEvent, useContext, useState } from "react";
import { useHistory } from "react-router";
import { NotificationContext } from "../../context/NotificationContext";
import { SignUp } from "../../firebase/auth/AuthHelper";
import EmailInput from "../lib/EmailInput";
import NameInput from "../lib/NameInput";
import PasswordInput from "../lib/PasswordInput";
import SocialButtons from "./SocialButtons";
import { logClient } from "../../firebase";

export default function SignUpForm() {
  //email, password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const history = useHistory();
  const notifactionCtx = useContext(NotificationContext);

  const handleSubmit = async (e: SyntheticEvent) => {
    //check if pass is strong check if 8 contains and number
    e.preventDefault();
    if (!email || !password || !name) {
      return;
    }
    try {
      await SignUp(email, password, name);
      notifactionCtx?.DisplayMessage(
        "success",
        "Check your email for a verification link"
      );
    } catch (error) {
      logClient.warn(error);
      notifactionCtx?.DisplayMessage(
        "error",
        "Something Went Wrong! Account already exist."
      );
      return;
    }
    history.push("/dashboard");
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-[4.5rem] w-auto"
            src="https://firebasestorage.googleapis.com/v0/b/whoseenit.appspot.com/o/images%2FBranding%2FIconTransparent.png?alt=media&token=01f7c0ff-f735-416c-b04b-b2fe45df0383"
            alt="Workflow"
          />

          <div className="mt-6 flex items-center justify-center">
            <ArrowLeftIcon
              className="mr-2 h-6 w-6 stroke-current hover:text-purple-500 dark:text-slate-50 dark:hover:text-purple-500"
              onClick={() => history.push("/")}
            />
            <h2 className="text-center text-3xl font-extrabold text-gray-900 dark:text-gray-100">
              Sign in to your account
            </h2>
          </div>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow dark:bg-gray-800 sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <NameInput name={name} setName={setName} />
              <EmailInput email={email} setEmail={setEmail} />
              <PasswordInput password={password} setPassword={setPassword} />

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  Sign Up
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500 dark:bg-gray-800 dark:text-white">
                    Or continue with
                  </span>
                </div>
              </div>
            </div>
            <SocialButtons />
            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span
                  onClick={() => history.push("login")}
                  className="cursor-pointer bg-white px-2 text-gray-500 hover:text-purple-500 dark:bg-gray-800 dark:text-white dark:hover:text-purple-500"
                >
                  Already have an account?
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
