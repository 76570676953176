import React, { useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  HomeIcon,
  DocumentAddIcon,
  MoonIcon,
  SunIcon,
  LogoutIcon,
} from "@heroicons/react/outline";
import classnames from "classnames";
import { ThemeContext } from "../context/ThemeContext";
import { Logout } from "../firebase/auth/AuthHelper";
import { AuthContext } from "../context/AuthContext";
import { logClient } from "../firebase";

export default function NavBar() {
  const themeCtx = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  const history = useHistory();

  const location = useLocation();

  const handleLogout = async () => {
    try {
      await Logout();
      history.push("/");
    } catch (error) {
      logClient.warn(error);
    }
  };

  if (
    (!user && location.pathname === "/") ||
    location.pathname === "/login" ||
    location.pathname === "/signup"
  ) {
    return <> </>;
  }
  return (
    <>
      <div className="flex flex-col items-center justify-start">
        <div
          className={classnames(
            "absolulte flex h-screen w-12 bg-gray-200 md:relative",
            "inset-y-0 left-0 "
          )}
        >
          <aside className="flex w-12 flex-col items-center bg-white text-gray-700 shadow dark:bg-black">
            <div className="mt-2 h-6 w-6">
              {themeCtx?.theme === "light" ? (
                <MoonIcon
                  className="stroke-current hover:text-purple-500 dark:text-white dark:hover:text-purple-600"
                  onClick={() => themeCtx?.ToggleTheme()}
                />
              ) : (
                <SunIcon
                  className="stroke-current hover:text-purple-600 dark:text-white dark:hover:text-purple-500"
                  onClick={() => themeCtx?.ToggleTheme()}
                />
              )}
            </div>
            <div className="flex h-full flex-col justify-center space-y-1">
              <div className="h-6 w-6">
                <Link to="/">
                  <HomeIcon className="stroke-current hover:text-purple-500 dark:text-white dark:hover:text-purple-500" />
                </Link>
              </div>

              <div className="h-6 w-6">
                <Link to="/create">
                  <DocumentAddIcon className="stroke-current hover:text-purple-500 dark:text-white dark:hover:text-purple-500" />
                </Link>
              </div>
            </div>

            <div className="mb-5 flex h-6 w-6 items-center md:mb-10">
              <LogoutIcon
                className="h-6 w-6 stroke-current hover:text-purple-500 dark:text-white dark:hover:text-purple-500"
                onClick={handleLogout}
              />
            </div>
          </aside>
        </div>
      </div>
    </>
  );
}
