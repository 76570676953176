import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { checkIfUserNameisValid } from "../../utils/loginHelper";

const NameInput = ({
  name,
  setName,
}: {
  name: string;
  setName: (email: string) => void;
}) => {
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    if (name.length < 3) {
      return;
    }
    checkIfUserNameisValid(name).then((valid) => {
      setIsValid(valid);
    });
  }, [name]);
  return (
    <>
      <div>
        <div className="flex flex-row justify-between align-middle">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
          >
            Username
          </label>
          <p
            className={classnames("text-sm text-red-600", {
              hidden: isValid,
            })}
            id="email-error"
          >
            This username is taken
          </p>
        </div>
        <div className="mt-1">
          <input
            id="name"
            name="name"
            type="text"
            autoComplete="given-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="JohnDoe"
            required
            className={classnames(
              "block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none  dark:border-gray-900  dark:bg-gray-600 dark:text-gray-200 sm:text-sm",
              {
                "border-gray-300 focus:border-purple-500 focus:ring-purple-500 dark:focus:border-purple-500 dark:focus:ring-purple-500":
                  isValid,
              },
              {
                "border-red-600 focus:border-red-600 focus:ring-red-600 dark:focus:border-red-600 dark:focus:ring-red-600":
                  !isValid,
              }
            )}
          />
        </div>
      </div>
    </>
  );
};

export default NameInput;
