import {
  collection,
  DocumentData,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { db } from "../../firebase";
import { GetDocument } from "./docController";

export const GetDocData = async (docId: string) => {
  //Collection
  const docProf = collection(db, "docProfile");
  const userDoc = collection(db, "UserDocRelation");

  //Queries
  const q = query(docProf, where("docId", "==", docId));
  const q2 = query(userDoc, where("docId", "==", docId));

  //data
  const doc = await GetDocument(docId);
  const docProfSnapShot = await getDocs(q);
  const userDocSnapshot = await getDocs(q2);

  let docProfData = {};
  let userDocData: DocumentData[] = [];
  let hasOpen = 0;
  let hasRead = 0;

  docProfSnapShot.forEach((docProf) => (docProfData = { ...docProf.data() }));

  userDocSnapshot.forEach((userDoc) => {
    const userData = userDoc.data();
    userDocData.push(userData);
    if (userData.hasOpen) hasOpen++;
    if (userData.hasRead) hasRead++;
  });

  const openRate = (hasOpen / userDocData.length) * 100;
  const readRate = (hasRead / userDocData.length) * 100;

  return {
    doc: { ...doc, openRate, readRate },
    userDoc: userDocData,
    docProf: docProfData,
  };
};
