import { DocumentData } from "@firebase/firestore";
import React, { FC, Fragment } from "react";
import { useHistory, Link } from "react-router-dom";
import { DateFormater } from "../../utils/formatHelper";

interface Props {
  data: DocumentData | undefined;
}
const AuthorView: FC<Props> = ({ data }) => {
  const history = useHistory();
  return (
    <>
      <div className="grid auto-rows-min space-y-8 divide-y-2 divide-solid divide-gray-500 divide-opacity-40 dark:divide-purple-400 dark:text-gray-200">
        <div className="flex h-fit flex-col">
          <div className="grid grid-cols-3">
            <span className="text-md font-bold">Open Rate</span>
            <span className="text-md text-center font-bold">
              {(Math.round(data?.doc?.openRate * 100) / 100).toFixed(2)}%
            </span>
          </div>
          <div className="grid grid-cols-3">
            <span className="text-md font-bold">Completion Rate</span>
            <span className="text-md text-center font-bold">
              {(Math.round(data?.doc?.readRate * 100) / 100).toFixed(2)}%
            </span>
          </div>
        </div>
        <div className="flex h-fit flex-col">
          <div className="!mt-6 grid grid-cols-3">
            <span className="text-md font-bold">Sent</span>
            <span className="text-md text-center">
              {DateFormater(data?.doc?.createdAt)}
            </span>
          </div>
          <div className="grid grid-cols-3">
            <span className="text-md font-bold">Due</span>
            <span className="text-md text-center">
              {DateFormater(data?.doc?.dueDate)}
            </span>
          </div>
        </div>
        <div className="flex h-fit flex-col">
          <div className="!mt-6 grid grid-cols-3">
            <span className="text-md font-bold">
              {data?.doc?.recipients}&nbsp;recipients
            </span>
          </div>
          <div className="mt-2 grid max-h-[7.875rem] grid-cols-1 overflow-y-scroll md:grid-cols-3">
            {data?.userDoc?.map((user: DocumentData) => {
              return (
                <Fragment key={user.userId}>
                  <Link to={`${history.location.pathname}/${user.userId}`}>
                    <span className="text-md cursor-pointer text-purple-500">
                      {user.email}
                    </span>
                  </Link>
                  <span className="text-md text-center">
                    {user.hasOpen ? "Opened" : "Not Opened"}
                  </span>
                  <span className="text-md text-center">
                    {user.hasRead ? "Confirmed" : "Unconfirmed"}
                  </span>
                </Fragment>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-md !mt-6 font-bold">Challenge Question</span>
          <span className="text-md">
            {data?.doc?.challengeQuestion || "N/A"}
          </span>
        </div>
      </div>
    </>
  );
};

export default AuthorView;
