import React, { FC } from "react";

interface Props {
  loomId: string;
}
const LoomEmbed: FC<Props> = ({ loomId }) => {
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "90%",
        height: "auto",
      }}
    >
      <iframe
        title="loomEmbed"
        src={`https://www.loom.com/embed/${loomId}`}
        frameBorder="0"
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </div>
  );
};

export default LoomEmbed;
