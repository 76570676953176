import React, { FC, useState } from "react";
import { ArrowsExpandIcon, XIcon } from "@heroicons/react/outline";
import Modal from "react-modal";
import Button from "../lib/Button";

interface Props {
  url: string;
}
const GoogleDocEmbed: FC<Props> = ({ url }) => {
  const [showModal, setShowModal] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "70vw",
      height: "75vh",
    },
  };

  const rootEl = document.getElementById("root");
  if (!rootEl) {
    return <> </>;
  }

  return (
    <>
      <div
        style={{
          position: "relative",
          paddingBottom: "90%",
          height: "auto",
        }}
      >
        <ArrowsExpandIcon
          className="ml-auto h-6 w-6 stroke-current text-gray-800 hover:text-purple-500"
          onClick={() => setShowModal(true)}
        />
        <iframe
          title="googleDocEmbed"
          src={url}
          frameBorder="0"
          style={{
            position: "absolute",
            top: "30px",
            left: 0,
            width: "100%",
            height: "100%",
          }}
        ></iframe>
      </div>
      <Modal
        isOpen={showModal}
        style={customStyles}
        onRequestClose={() => {
          setShowModal(false);
          return true;
        }}
        appElement={rootEl}
      >
        <Button variant="circle" size="xs" className="float-right">
          <XIcon className="h-5 w-5" onClick={() => setShowModal(false)} />
        </Button>
        <iframe
          title="googleDocEmbed"
          src={url}
          frameBorder="0"
          style={{
            position: "absolute",
            top: "60px",
            left: 0,
            width: "100%",
            height: "100%",
          }}
        ></iframe>
      </Modal>
    </>
  );
};

export default GoogleDocEmbed;
