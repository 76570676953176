import {
  collection,
  addDoc,
  getDoc,
  setDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db, logClient } from "../../firebase";

export const documentProfile = collection(db, "docProfile");

export type documentProfileType = {
  docId: string | undefined;
  userIds: any;
};

export const AddDocumentProfile = async (docProfile: documentProfileType) => {
  try {
    const docProfRef = await addDoc(documentProfile, {
      ...docProfile,
      createdAt: serverTimestamp(),
    });
    logClient.log("Document written with ID: ", docProfRef.id);
    return docProfRef;
  } catch (error) {
    logClient.warn(error);
  }
};
//TEST
export const UpdateDocumentProfile = async (
  docProfileId: string,
  data: any
) => {
  try {
    const docProfRef = doc(db, "docProfile", docProfileId);
    await setDoc(
      docProfRef,
      {
        timestamp: serverTimestamp(),
        ...data,
      },
      { merge: true }
    );
    logClient.log(`Document with id: ${docProfileId} updated`);
  } catch (error) {
    logClient.warn(error);
  }
};
export const GetDocumentProfile = async (docProfileId: string) => {
  const docProfRef = doc(db, "docProfile", docProfileId);
  try {
    const docProfSnap = await getDoc(docProfRef);
    if (docProfSnap.exists()) {
      return docProfSnap.data();
    } else {
      logClient.warn("DocProfile not found");
    }
  } catch (error) {
    logClient.warn(error);
  }
};

export const DeleteDocumentProfile = async (docProfileId: string) => {
  const docProfRef = doc(db, "docProfile", docProfileId);
  try {
    await deleteDoc(docProfRef);
    logClient.log(`Doc with id: ${docProfRef.id} deleted`);
  } catch (error) {
    logClient.warn(error);
  }
};

export const UpdateUserIdArray = async (
  docProfId: string,
  userId: string,
  action: string
) => {
  const docProfRef = doc(db, "docProfile", docProfId);
  try {
    await updateDoc(docProfRef, {
      userIds: action === "add" ? arrayUnion(userId) : arrayRemove(userId),
    });
  } catch (error) {
    logClient.warn(error);
  }
};
