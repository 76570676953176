import React, { createContext, FC, useLayoutEffect, useState } from "react";

interface ThemeContextInterface {
  theme: string | null;
  ToggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextInterface | null>(null);

const ThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState(window.localStorage.getItem("theme"));

  if (!theme) {
    setTheme(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );
  }

  useLayoutEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      window.localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      window.localStorage.setItem("theme", "light");
    }
  }, [theme]);

  const ToggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };

  const value = {
    theme,
    ToggleTheme,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
