import React, { FC } from "react";
import SelectDropdown from "../lib/SelectDropdown";

const UserInput: FC<any> = ({
  users,
  selectedUsers,
  setSelectedUsers,
  setUsers,
}) => {
  return (
    <div className="mt-1">
      <label
        htmlFor="select"
        className="block text-sm font-medium text-gray-700 dark:text-gray-200"
      >
        User Select
      </label>
      <div className="mt-1">
        <SelectDropdown
          users={users.map((u: any) => {
            return {
              value: u.id,
              label: u.email,
            };
          })}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          setUsers={setUsers}
        />
      </div>
    </div>
  );
};

export default UserInput;
