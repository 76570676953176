import React, { useEffect, useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { hasNumber } from "../../utils/loginHelper";
import classnames from "classnames";
import { useLocation } from "react-router-dom";

const PasswordInput = ({
  password,
  setPassword,
}: {
  password: string;
  setPassword: (password: string) => void;
}) => {
  const [showPass, setShowPass] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (!password || password.length < 4) {
      setMessage("");
      return;
    }
    if (password.length < 6) {
      setMessage("Password too short");
      setIsError(true);
      return;
    }
    if (!hasNumber(password)) {
      setMessage("Password missing number");
      setIsError(true);
      return;
    }
    setIsError(false);
    setMessage("Password is good");
  }, [password]);

  return (
    <>
      <div>
        <div className="flex flex-row justify-between align-middle">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
          >
            Password
          </label>
          {location.pathname === "/signup" && (
            <p
              className={classnames(
                "text-sm",
                {
                  hidden: !message,
                },
                { "text-red-600": isError },
                { "text-green-500": !isError }
              )}
              id="password-error"
            >
              {message}
            </p>
          )}
        </div>
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            id="password"
            name="password"
            type={showPass ? "text" : "password"}
            autoComplete="current-password"
            required
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 dark:border-gray-900 dark:bg-gray-600 dark:text-gray-200 dark:focus:border-purple-500 dark:focus:ring-purple-500 sm:text-sm"
          />
          <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
            {showPass ? (
              <EyeOffIcon
                className="h-5 w-5 stroke-current text-gray-400 hover:text-purple-500 dark:text-gray-200 dark:hover:text-purple-500"
                aria-hidden="true"
                onClick={() => setShowPass(!showPass)}
              />
            ) : (
              <EyeIcon
                className="h-5 w-5 stroke-current text-gray-400 hover:text-purple-500 dark:text-gray-200 dark:hover:text-purple-500"
                aria-hidden="true"
                onClick={() => setShowPass(!showPass)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PasswordInput;
