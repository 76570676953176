import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "../components/NavBar";
import Logo from "../components/Logo";
import { Home, Login, SignUp, Create, Dashboard, Document } from "../pages";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  return (
    <Router>
      <NavBar />
      <Logo />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/create" component={Create} />
        <PrivateRoute path="/document/:docId/:userId?" component={Document} />
        <Route exact path="/">
          <Home />
        </Route>
        <Route>
          <span className="dark:text-white">404 not found </span>
        </Route>
      </Switch>
    </Router>
  );
};

export { Routes };
